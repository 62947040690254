import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout";

const Pedro = () => {
  return (
    <Layout>
      <Head title="Pedro Sequeira" />
      <h3>Pedro Sequeira</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Trenčín, Slovakia
      </h4> */}
      <p>
        <OutboundLink href="https://ello.co/sequeira">Ello</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15875392/ello-optimized-fd176aa5.jpg"
        alt="Pedro Sequeira art"
      />
      <p style={{ marginBottom: 0 }}>Untitled 2023</p>
      <p style={{ marginBottom: 75 }}>
        Ballpoint on paper 11 x 8 1/4 inches (28 x 21 cm)
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15874266/ello-optimized-01d40ec3.jpg"
        alt="Pedro Sequeira art"
      />
      <p style={{ marginBottom: 0 }}>Untitled 2023</p>
      <p style={{ marginBottom: 75 }}>
        Ballpoint on paper 11 x 8 1/4 inches (28 x 21 cm)
      </p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15867936/ello-optimized-32c59837.jpg"
        alt="Pedro Sequeira art"
      />
      <p style={{ marginBottom: 0 }}>Untitled 2023</p>
      <p style={{ marginBottom: 75 }}>
        Ballpoint on paper 11 x 8 1/4 inches (28 x 21 cm)
      </p>
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15866302/ello-optimized-4fb9723c.jpg"
        alt="Pedro Sequeira art"
      />
      <p style={{ marginBottom: 0 }}>Untitled 2023</p>
      <p style={{ marginBottom: 75 }}>
        Ballpoint on paper 11 x 8 1/4 inches (28 x 21 cm)
      </p>
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15855900/ello-optimized-06264f3d.jpg"
        alt="Pedro Sequeira art"
      />
      <p style={{ marginBottom: 0 }}>Untitled 2023</p>
      <p style={{ marginBottom: 75 }}>
        Ballpoint on paper 11 x 8 1/4 inches (28 x 21 cm)
      </p>
      <img
        src="https://ello-direct-uploads.s3.amazonaws.com/uploads/e2204229-e689-45b6-9fd3-d699b44a5acb/F519DFD0-C066-48FE-8213-6D117D66CC22.jpg"
        alt="Pedro Sequeira art"
      />
    </Layout>
  );
};

export default Pedro;
